import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  EquisButtonBaseSizes,
  EquisAnchorType as Target,
} from '@tn-equis/core/components/button';

import { getWidth, mapStyle } from '@/utils/link';
import { DataLayerService } from '@/services/data-layer.service';

import { ILink } from './link.types';
import { SectionService } from '@/services/section.service';
import { PlatformService } from '@/services/platform.service';
import { skipPathname } from '../../../common/utils/skip-pathname';
import { Router } from '@angular/router';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { AmplitudeExperiment } from '../../../common/types/data-layer';

@Component({
  selector: 'nx-link',
  templateUrl: './link.component.html',
})
export class LinkComponent implements ILink, OnInit {
  constructor(
    private dataLayer: DataLayerService,
    private section: SectionService,
    private platform: PlatformService,
    private router: Router,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: ILink['data'];
  // OPTIONAL INPUTS
  @Input() width?: ILink['width'] = 'full';
  @Input() size: ILink['size'] = EquisButtonBaseSizes.LARGE;

  // PROPERTIES
  absoluteHref!: string;

  /**
   * Gets the button style, based on the style input.
   */
  get buttonStyle() {
    return mapStyle(this.data.style);
  }

  /**
   * Anchor type for the Link button.
   */
  get target() {
    return this.data.opensNewTab ? Target._BLANK : Target._SELF;
  }

  get buttonWidth() {
    return getWidth(this.width);
  }

  /*This method push to GTM datalayer */
  onClick(event: Event) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      this.data.dataLayer,
      this.section.sectionName,
      experiment
    );
    const url = new URL(this.absoluteHref);
    if (
      window.location.host !== 'localhost' &&
      !url.hostname.includes(window.location.host)
    )
      return;
    if (this.data?.disableNavigation) return;
    if (!skipPathname.test(url.href)) {
      event.preventDefault();
      this.router.navigate([url.pathname]);
    }
  }

  // HOOKS
  ngOnInit(): void {
    this.absoluteHref = this.platform.getAbsoluteURL(this.data.url);
  }
}

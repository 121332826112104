<div class="navbar-top">
  <div class="container">
    <div class="logo-and-tabs-container">
      <div class="logo-container">
        <app-button
          *ngIf="isMenuOpen"
          class="close-icon"
          type="menu"
          size="medium"
          icon="icon-cross"
          (click)="toggleMenu()"
        >
        </app-button>
        <app-button
          *ngIf="!isMenuOpen"
          class="close-icon mobil"
          type="menu"
          size="medium"
          icon="icon-menu"
          (click)="toggleMenu()"
        ></app-button>
        <!-- TODO check if we need to change content model to accept url path for logo -->
        <a
          routerLink="/"
          nxAbsoluteAnchor="/"
          (click)="pushGoHome()"
          class="logo"
          rel="dofollow"
        >
          <picture [nxContentfulImage]="data.logo">
            <source />
            <source />
            <source />
            <img loading="eager" class="logo-size" />
          </picture>
        </a>
      </div>
      <nx-navbar-tabs
        class="nx-navbar-tabs desktop-content"
        [data]="data"
      ></nx-navbar-tabs>
      <nx-navbar-tabs
        *ngIf="isMenuOpen"
        class="nx-navbar-tabs mobile-content"
        [data]="data"
      ></nx-navbar-tabs>
    </div>
    <div class="buttons-container">
      <label
        tabindex="0"
        equisDropdown
        [dropdownDirection]="helpDropdownProps.dropdownDirection"
        [marginSide]="helpDropdownProps.marginSide"
        [marginTop]="helpDropdownProps.marginTop"
        [widthType]="helpDropdownProps.widthType"
        [widthPixels]="helpDropdownProps.widthPixels"
        [closeOnClick]="helpDropdownProps.closeOnClick"
        [dropdownList]="helpDropdownProps.dropdownList"
        (dropdownItem)="onDropdownItemClick($event)"
      >
        <app-button
          text="Ayuda"
          size="medium"
          type="link"
          (click)="pushHelp()"
        ></app-button>
      </label>
      <nx-link
        *ngIf="activeTab.signInButton.label === 'Ingresar a tu comercio'"
        [data]="activeTab.signInButton"
        [size]="SIGNIN_SIZE"
      ></nx-link>
      <nx-button-download-app
        [data]="data.downloadAppButton.fields"
        [size]="DOWNLOAD_APP_SIZE"
      ></nx-button-download-app>
    </div>
  </div>
</div>

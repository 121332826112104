<section [style.backgroundColor]="data.backgroundColor" #section>
  <div class="container">
    <div class="tabs">
      <div
        class="tab-content"
        [ngClass]="{ 'tab-content--active': !data.category }"
        [routerLink]="!data.category ? null : ['/blog']"
      >
        Todos
      </div>
      <div
        *ngFor="let categorie of data.categories"
        class="tab-content"
        [ngClass]="{ 'tab-content--active': categorie.value === data.category }"
        [routerLink]="
          categorie.value === data.category ? null : ['/blog/', categorie.value]
        "
      >
        {{ categorie.label }}
      </div>
    </div>

    <ul class="components-list" [class.three-columns]="true">
      <li class="list-item" *ngFor="let article of articles?.items">
        <nx-card-large [data]="article.fields"></nx-card-large>
      </li>
    </ul>

    <equis-paginator-big
      class="paginator"
      *ngIf="!platform.isServer && articles"
      [currentPage]="currentPage"
      [totalItems]="calculateTotalPages"
      (changePage)="changePage($event)"
    >
    </equis-paginator-big>
  </div>
</section>

<ng-container *ngIf="metadataService.isBlog; else normal_content">
  <div class="container">
    <div class="title">
      <h1>{{ title.getTitle() }}</h1>
    </div>

    <div class="wrapper">
      <span class="date">{{ data.date | date : 'd MMM yyyy' }}</span> | Tiempo
      de lectura: {{ estimatedReadingTime }} min |
      <span class="share" (click)="onShare()">Compartir</span>
    </div>

    <div class="image-container">
      <img
        [src]="metadataService.getMetaImage().image"
        [alt]="metadataService.getMetaImage().imageAlt"
      />
    </div>
  </div>
</ng-container>

<ng-template #normal_content>
  <div class="container content">
    <span class="date">{{ data.date | date : 'd MMM yyyy' }}</span> | Tiempo de
    lectura: {{ estimatedReadingTime }} min |
    <span class="share" (click)="onShare()">Compartir</span>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogArticlesComponent } from './blog-articles.component';
import { EquisPaginatorBigModule } from '@tn-equis/core/components/paginator-big';
import { EquisNavigatorService } from '@tn-equis/core/services';
import CardLargeModule from '@/atoms/card-large/card-large.module';
import { SectionService } from '../../../services/section.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BlogArticlesComponent],
  imports: [
    CommonModule,
    EquisPaginatorBigModule,
    CardLargeModule,
    RouterModule,
  ],
  exports: [BlogArticlesComponent],
  providers: [EquisNavigatorService, SectionService],
})
export default class BlogArticlesModule {
  public static component = BlogArticlesComponent;
}
